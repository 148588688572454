const CoreServices = () => {
    return (
      <section className="pt-36 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="font-lora text-[28px] md:text-5xl font-medium text-center mb-5">Our Core Services</h2>
          <p className="font-inter text-center text-base text-gray-600 pt-0 mb-12">
            From initial concept to final execution, we’re here to guide you every step of the way.
          </p>

          <div className="font-inter flex flex-col lg:flex-row items-center justify-center space-x-10 text-center mx-14">
            {/* Service 1 */}
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 max-w-sm mx-auto">
              <img src="/images/icon-001.png" alt="Service 1" className="h-16 mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-2">Research Publication</h3>
              <p className="text-gray-600">
              We provide a platform for publishing peer-reviewed research in culture, philosophy, and society, ensuring high academic standards.
              </p>
            </div>
  
            {/* Service 2 */}
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 max-w-sm mx-auto">
              <img src="/images/icon-002.png" alt="Service 2" className="h-16 mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-2">Creative Writing</h3>
              <p className="text-gray-600">
              We offer a platform for publishing creative writing, showcasing original works of fiction and essays with a commitment to quality.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 max-w-sm mx-auto">
              <img src="/images/icon-003.png" alt="Service 3" className="h-16 mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-2">PhD Theses Writing</h3>
              <p className="text-gray-600">
              We offer a platform for publishing creative writing, showcasing original works of fiction and essays with a commitment to quality.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 max-w-sm mx-auto">
              <img src="/images/icon-004.png" alt="Service 4" className="h-16 mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-2">Admission Counseling</h3>
              <p className="text-gray-600">
              We offer admission counseling services, providing expert guidance and personalized support to help students in a better manner.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center">

          <p className="font-lora font-medium text-3xl mt-56 mb-12 mx-60 text-center">“You are the sum total of everything you’ve ever seen, heard, eaten, smelled, been told, forgot – it’s all there. Everything influences each of us, and because of that I try to make sure that my experiences are positive.”</p>

          <h6 className="font-inter text-lg pb-1">Maya Angelou</h6>
          <p className="font-inter text-stone-500 text-base mb-16">Author, <i>"I Know Why the Caged Bird Sings"</i></p>
        </div>
      </section>
    );
  };
  
  export default CoreServices;
  