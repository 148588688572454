import React from 'react';
import Header from './Header';
import pdfjan from '../documents/AJH 2018/AJH-ISSUE-JAN-2018.pdf';
import pdffeb from '../documents/AJH 2018/AJH-ISSUE-FEB-2018.pdf';
import pdfapril from '../documents/AJH 2018/AJH-ISSUE-APRIL-2018.pdf';
import pdfjune from '../documents/AJH 2018/AJH-ISSUE-JUNE-2018.pdf';
import pdfaug from '../documents/AJH 2018/AJH-ISSUE-August-2018-Special.pdf';
import pdfocto from '../documents/AJH 2018/AJH-ISSUE-OCTOBER-2018.pdf';
import pdfdec from '../documents/AJH 2018/AJH-ISSUE-DECEMBER-2018.pdf';

const Journal2018 = () => {

    const journals = [
        { title: "January Issue", pdfLink: pdfjan},
        { title: "February Issue", pdfLink: pdffeb},
        { title: "April Issue", pdfLink: pdfapril},
        { title: "June Issue", pdfLink: pdfjune},
        { title: "August Issue", pdfLink: pdfaug},
        { title: "October Issue", pdfLink: pdfocto},
        { title: "December Issue", pdfLink: pdfdec},
    ];

    return (

        <div>
            <Header/>

            <div className="font-lora min-h-screen bg-gray-100 py-16 px-4 mt-24">

                <section className="mb-12 text-center">
                    <h1 className="text-4xl font-bold text-gray-800">Journals 2018</h1>
                </section>

                {/* Journals Grid Section */}
                <section className="container mx-auto">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 text-center">
                        {journals.map((journal, index) => (
                            <div
                                key={index}
                                className="bg-white p-6 shadow-lg hover:shadow-xl transition-shadow duration-300"
                            >
                                <h2 className="text-xl font-normal text-gray-800 mb-4">{journal.title}</h2>
                                <a
                                    href={journal.pdfLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="font-inter bg-black text-white py-2 px-4 inline-block hover:bg-slate-700 transition-colors duration-300"
                                >
                                    Download
                                </a>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Journal2018;
