import React from 'react';
import Header from './Header';
import pdfjan from '../documents/AJH 2015/AJH-ISSUE-Jan-2015.pdf';
import pdfmarch from '../documents/AJH 2015/AJH-ISSUE-March-2015.pdf';
import pdfjuly from '../documents/AJH 2015/AJH-ISSUE-July-2015.pdf';
import pdfsept from '../documents/AJH 2015/AJH-ISSUE-September-2015.pdf';
import pdfnov from '../documents/AJH 2015/AJH-ISSUE-November-2015.pdf';

const Journal2015 = () => {

    const journals = [
        { title: "January Issue", pdfLink: pdfjan},
        { title: "March Issue", pdfLink: pdfmarch},
        { title: "July Issue", pdfLink: pdfjuly},
        { title: "September Issue", pdfLink: pdfsept},
        { title: "November Issue", pdfLink: pdfnov},
    ];

    return (

        <div>
            <Header/>

            <div className="font-lora min-h-screen bg-gray-100 py-16 px-4 mt-24">

                <section className="mb-12 text-center">
                    <h1 className="text-4xl font-bold text-gray-800">Journals 2015</h1>
                </section>

                {/* Journals Grid Section */}
                <section className="container mx-auto">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 text-center">
                        {journals.map((journal, index) => (
                            <div
                                key={index}
                                className="bg-white p-6 shadow-lg hover:shadow-xl transition-shadow duration-300"
                            >
                                <h2 className="text-xl font-normal text-gray-800 mb-4">{journal.title}</h2>
                                <a
                                    href={journal.pdfLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="font-inter bg-black text-white py-2 px-4 inline-block hover:bg-slate-700 transition-colors duration-300"
                                >
                                    Download
                                </a>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Journal2015;
