import React from 'react';
import Header from './Header';

const Readmore = () => {
  return (
    <div>
      <Header/>

      <section className="py-16 px-4 mt-28 bg-white">

        <div className="flex flex-col items-center">
          <h2 className="font-lora text-4xl font-bold mb-6">Alchemist Journal of Humanities</h2>

          <div className="font-inter text-base text-gray-700 max-w-2xl mx-auto">
          <p>
          Welcome to the Alchemist Journal of Humanities. Here, you can delve deeper into the rich content and academic excellence that our journal has to offer. Our publication is dedicated to advancing knowledge and fostering intellectual dialogue in the fields of culture, philosophy, and society.
          </p><br></br>

          <p>
          <b>Research Articles: </b>Dive into a wealth of peer-reviewed research articles that explore various aspects of the humanities. Our research section features groundbreaking studies, theoretical analyses, and empirical investigations across a wide range of topics, from historical narratives to contemporary cultural phenomena.
          </p><br></br>

          <p>
          <b>Creative Writing: </b>Discover a vibrant collection of original fiction, poetry, and essays. Our creative writing section showcases the artistic talents of writers from around the world, offering fresh perspectives and imaginative explorations of human experience.
          </p><br></br>

          <p>
          <b>PhD Theses: </b>Access in-depth doctoral theses that contribute significantly to the field of humanities. Our thesis section provides a platform for emerging scholars to present their comprehensive research, enriching the academic discourse with their findings and insights.
          </p><br></br>

          <p>
          <b>Admission Counseling: </b>Learn more about our admission counseling services, designed to guide students through the complexities of the college and university application process. We offer personalized advice and strategic support to help applicants achieve their educational and career goals.
          </p><br></br>

          <p>
          <b>Get Involved: </b>Interested in contributing to our journal? Explore opportunities for submission, review, and collaboration. We welcome diverse perspectives and encourage you to share your research, creative works, and expertise with our community. For more detailed information, access our full archives, or inquire about specific topics, please visit the respective sections on our website or contact us directly at <b>raviphd2012@gmail.com.</b>Thank you for your interest in the Alchemist Journal of Humanities. We look forward to your continued engagement and contributions to the field.
          </p><br></br>

          </div>
        </div>
      </section>
    </div>
  );
};

export default Readmore;
