import React from 'react';
import Header from './Header';
import pdfaugust from '../documents/AJH2013/AJH-August-2013.pdf';
import pdfjune from '../documents/AJH2013/AJH-ISSUE-JUNE-2013.pdf';
import pdfmarch from '../documents/AJH2013/AJH-ISSUE-MARCH-2013.pdf';
import pdfnov from '../documents/AJH2013/AJH-ISSUE-November-2013.pdf';
import pdfoct from '../documents/AJH2013/AJH-ISSUE-October-2013.pdf';

const Journal2013 = () => {

    const journals = [
        { title: "August Issue", pdfLink: pdfaugust},
        { title: "June Issue", pdfLink: pdfjune},
        { title: "March Issue", pdfLink: pdfmarch},
        { title: "November Issue", pdfLink: pdfnov},
        { title: "October Issue", pdfLink: pdfoct},
    ];

    return (

        <div>
            <Header/>

            <div className="font-lora min-h-screen bg-gray-100 py-16 px-4 mt-24">

                <section className="mb-12 text-center">
                    <h1 className="text-4xl font-bold text-gray-800">Journals 2013</h1>
                </section>

                {/* Journals Grid Section */}
                <section className="container mx-auto">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 text-center">
                        {journals.map((journal, index) => (
                            <div
                                key={index}
                                className="bg-white p-6 shadow-lg hover:shadow-xl transition-shadow duration-300"
                            >
                                <h2 className="text-xl font-normal text-gray-800 mb-4">{journal.title}</h2>
                                <a
                                    href={journal.pdfLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="font-inter bg-black text-white py-2 px-4 inline-block hover:bg-slate-700 transition-colors duration-300"
                                >
                                    Download
                                </a>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Journal2013;
