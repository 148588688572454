import React from 'react';
import Header from './Header';

const PreviousJournals = () => {
  return (
    <div>
      <Header/>

      <section className="py-16 px-4 mt-28 bg-white">

        <div className="flex flex-col items-center">
          <h2 className="font-lora text-4xl font-bold mb-6">Previous Journals</h2>

          <div className="font-inter text-base text-gray-700 max-w-2xl mx-auto">

            <h4 className='pb-2'><b>Year: </b><a className='hover:underline' href='/year-2013'>2013</a></h4>
            <h4 className='pb-2'><b>Year: </b><a className='hover:underline' href='/year-2014'> 2014</a></h4>
            <h4 className='pb-2'><b>Year: </b><a className='hover:underline' href='/year-2015'> 2015</a></h4>
            <h4 className='pb-2'><b>Year: </b><a className='hover:underline' href='/year-2016'> 2016</a></h4>
            <h4 className='pb-2'><b>Year: </b><a className='hover:underline' href='/year-2017'> 2017</a></h4>
            <h4 className='pb-2'><b>Year: </b><a className='hover:underline' href='/year-2018'> 2018</a></h4>
            <h4 className='pb-2'><b>Year: </b><a className='hover:underline' href='/year-2019'> 2019</a></h4>
            <h4 className='pb-2'><b>Year: </b><a className='hover:underline' href='/year-2020'> 2020</a></h4>

          </div>
        </div>
      </section>

    </div>
  );
};

export default PreviousJournals;
