import React from 'react';

const Action = () => {
    return (
        <section
            className="relative bg-cover bg-center h-[700px] flex items-center justify-center text-white"
            style={{ backgroundImage: "url('/images/background3.jpg')" }}>

            <div className="absolute inset-0 bg-black bg-opacity-5"></div>

            <div className="relative z-10 text-center px-4">
                <h2 className="font-lora text-4xl md:text-5xl font-semibold mb-6">
                    Start Your Creative <span className="block md:inline lg:block">Story Today.</span> 
                </h2>
                <p className="font-inter font-normal text-base md:text-base mb-8 max-w-lg mx-auto">We’re here to listen, collaborate, and drive your vision forward. Whether it’s through an email or a phone call, initiating a conversation is the first step towards unparalleled creative success.</p>  

                <div className="flex flex-row justify-center space-x-14 text-center">
                    <a href="/contact" className="bg-transparent border font-semibold text-white p-5 hover:bg-black">BOOK A MEETING</a>
                    <a href="tel:+91 7015434659" className="bg-white font-semibold text-black p-5 hover:bg-black hover:text-white">CALL US (+91) 7015434659</a>
                </div>
            </div>
        </section>
    );
};

export default Action;
