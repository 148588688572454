// src/pages/HomePage.js
import React from 'react';
import Header from './Header';
import CoreServices from './CoreServices';
import Action from './Action';

const HomePage = () => {
  return (
    <div>
      <div className="flex flex-col items-center justify-center h-screen bg-cover bg-center" style={{ backgroundImage: "url('/images/background2.jpg')" }}>

        <Header />
        <main className="font-lora flex flex-col items-center mt-20 text-center text-black">
          <h1 className="text-[30px] md:text-6xl pb-2 font-medium mb-2 mt-10">Alchemist Journal of <span className="block md:inline lg:block">Humanities</span></h1>
          <h1 className="text-[30px] md:text-5xl mb-10 pb-2">(ISSN 2320-9216)</h1>
          <p className="font-inter font-light mb-6 max-w-2xl">
            Our publication is dedicated to fostering scholarly dialogue and innovative research across the humanities, providing a platform for academic rigor and intellectual creativity. Join us as we illuminate narratives that shape our world.
          </p>

          <div className="flex space-x-4">
            <a href="/contact" className="bg-white font-semibold text-black p-5 hover:bg-stone-50">CONTACT US</a>
            <a href="/read-more" className="bg-black font-semibold text-white p-5 hover:bg-neutral-800">READ MORE</a>
          </div>
        </main>
      </div>

      <CoreServices/>
      <Action/>
    </div>

  );
};
export default HomePage;
