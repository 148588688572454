import React from 'react';

const Header = () => {

    return(
        <header className="absolute top-0 w-full p-4 bg-white bg-opacity-80 shadow-md">
            <div className="container mx-auto flex items-center justify-between">

            <div className="flex items-center">
                <a href='/'><img src={'/images/logo.png'} alt="Logo" className="h-20 w-20"/></a>
            </div>

            <nav className="flex-grow text-center">
                <ul className="font-lora font-bold flex justify-center space-x-14">
                <li>
                    <a href="/" className="text-gray-700 hover:text-grey-200">Home</a>
                </li>

                <li>
                    <a href="/about" className="text-gray-700 hover:text-grey-200">About Us</a>
                </li>

                <li>
                    <a href="/previous-journals" className="text-gray-700 hover:text-grey-200">Previous Journals</a>
                </li>

                <li>
                    <a href="/editorial" className="text-gray-700 hover:text-grey-200">Editorial Board</a>
                </li>
                
                </ul>
            </nav>
            </div>
        </header>
    );
};

export default Header;