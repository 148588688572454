import React from 'react';
import Header from './Header';

const AboutPage = () => {
  return (
    <div>
      <Header/>

      <section className="py-16 px-4 mt-28 bg-white">

        <div className="flex flex-col items-center">
          <h2 className="font-lora text-4xl font-bold mb-6">About Us</h2>

          <div className="font-inter text-base text-gray-700 max-w-2xl mx-auto">
          <p>
          Welcome to the Alchemist Journal of Humanities, a premier academic platform dedicated to the exploration and dissemination of knowledge in the fields of culture, philosophy, and society. Our journal is committed to fostering intellectual curiosity, academic rigor, and creative innovation, offering a comprehensive space for scholars, writers, and students to share their insights and research.
          </p><br></br>

          <p>
          <b>Our Mission:</b> At the Alchemist Journal of Humanities, our mission is to cultivate a deeper understanding of the human experience through the study of the humanities. We aim to provide a diverse and inclusive platform that encourages scholarly dialogue, critical analysis, and creative expression. By publishing high-quality research and creative works, we seek to illuminate the complex narratives that shape our world and contribute to the advancement of knowledge.
          </p><br></br>

          <p>
          <b>Our Commitment:</b> We are dedicated to maintaining the highest standards of academic excellence and integrity. Our editorial board comprises esteemed scholars and professionals who bring their expertise and passion to every aspect of our journal. We believe in the power of the humanities to enrich lives, foster understanding, and drive societal progress.
          </p><br></br>

          <p>
          <b>Join Us:</b> Whether you are a researcher, writer, student, or enthusiast, we invite you to join our community. Submit your work, explore our publications, and engage with the vibrant intellectual discourse that defines the Alchemist Journal of Humanities. Together, we can contribute to a deeper appreciation of the humanities and their vital role in our world.
          </p><br></br>

          <p>
          <b>Contact Us:</b> For more information, submissions, or inquiries, please visit our Contact Page or email us at <b>raviphd2012@gmail.com.</b> We look forward to connecting with you and supporting your academic and creative endeavors.
          </p><br></br>

          </div>
        </div>
      </section>

    </div>
  );
};

export default AboutPage;
