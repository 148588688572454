import React from 'react';
import Header from './Header';

const Editorial = () => {
  return (
    <div>
      <Header/>

      <section className="py-16 px-4 mt-28 bg-white">

        <div className="flex flex-col items-center">
          <h2 className="font-lora text-4xl font-bold mb-6">Editorial Board</h2>

          <div className="font-inter text-base text-gray-700 max-w-2xl mx-auto">

          <p>
          <b>Prof. Dr. Ravinder Kumar: </b> Editor in Chief
          </p><br></br>

          <p>
          <b>Prof. Cheryl Stobie:</b> Department of English, University of Kwazulu Natal, South Africa

          </p><br></br>

          <p>
          <b>Prof. Bedjaoui Fewzia:</b> Head, British Literature and Civilization, Faculty of Letters and Human Sciences, Djillali Liabes University of Algeria

          </p><br></br>

          <p>
          <b>Dr. Mohamed E. Dawoud:</b> Associate Professor of English Literature, Faculty of Arts, Damanhour University, Egypt
          </p><br></br>

          <p>
          <b>Jean Philip Imbert:</b> Lecturer, Comparative Literature, Dublin City University, Ireland
          </p><br></br>

          </div>
        </div>
      </section>

    </div>
  );
};

export default Editorial;
