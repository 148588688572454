// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './pages/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import Editorial from './pages/Editorial';
import PreviousJournals from './pages/PreviousJournals';
import Year2013 from './pages/Year2013';
import Year2014 from './pages/Year2014';
import Year2015 from './pages/Year2015';
import Year2016 from './pages/Year2016';
import Year2017 from './pages/Year2017';
import Year2018 from './pages/Year2018';
import Year2019 from './pages/Year2019';
import Year2020 from './pages/Year2020';
import Readmore from './pages/Readmore';

function App() {
  return (
    <Router>
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/editorial" element={<Editorial />} />
          <Route path="/previous-journals" element={<PreviousJournals />} />
          <Route path="/year-2013" element={<Year2013 />} />
          <Route path="/year-2014" element={<Year2014 />} />
          <Route path="/year-2015" element={<Year2015 />} />
          <Route path="/year-2016" element={<Year2016 />} />
          <Route path="/year-2017" element={<Year2017 />} />
          <Route path="/year-2018" element={<Year2018 />} />
          <Route path="/year-2019" element={<Year2019 />} />
          <Route path="/year-2020" element={<Year2020 />} />
          <Route path="/read-more" element={<Readmore />} />
        </Routes>
      </main>

      <Footer/>
    </Router>
  );
}

export default App;
