import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-400 py-10">
      <div className="container mx-auto px-4">
        {/* Top section with Logo, Nav, and Contact */}

        <div className="flex flex-row items-center justify-center space-x-72">
          
          <div className="flex flex-col items-center md:items-start">
            <h1 className="text-white text-2xl font-bold mb-4">J H</h1>
          </div>

          {/* Navigation Links */}
          <div className="flex flex-col items-center md:items-start">
            <h3 className="text-white text-xl font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><a href="/" className="hover:text-white">Home</a></li>
              <li><a href="/about" className="hover:text-white">About Us</a></li>
              <li><a href="/previous-journals" className="hover:text-white">Previous Journals</a></li>
              <li><a href="/editorial" className="hover:text-white">Editorial Board</a></li>
              <li><a href="/contact" className="hover:text-white">Contact Us</a></li>
            </ul>
          </div>

          {/* Contact Information */}
          <div className="flex flex-col items-center md:items-start">
            <h3 className="text-white text-xl font-semibold mb-4">Contact Us</h3>
            <p className="mb-2">
              <span className="font-semibold">Email:</span> raviphd2012@gmail.com
            </p>
            <p className="mb-2">
              <span className="font-semibold">Address:</span> Sector 3, House No. 1410, Kurukshetra, Haryana, India
            </p>
          </div>
        </div>

        {/* Bottom section with copyright */}
        <div className="border-t border-gray-700 mt-8 pt-6 text-center">
          <p className="text-gray-500">
            © {new Date().getFullYear()} Alchemist Journal of Humanities. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
