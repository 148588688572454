import React from 'react';
import Header from './Header';

const ContactPage = () => {
  return (
    <div>
      <Header/>

      <section className="py-16 px-4 mt-28 bg-white">

        <div className="flex flex-col items-center">
          <h2 className="font-lora text-4xl font-bold mb-6">Contact Us</h2>

          <div className="font-inter text-base text-gray-700 max-w-2xl mx-auto">
          <p>
          We value your interest in the Alchemist Journal of Humanities and are here to assist with any questions, submissions, or inquiries you may have. Whether you are a researcher, writer, student, or reader, we are committed to providing the support and information you need.
          </p><br></br>

          <p>
          <b>General Inquiries: </b>For general questions about the Alchemist Journal of Humanities, our mission, or our services, please email us at raviphd2012@gmail.com


          </p><br></br>

          <p>
          <b>Submissions: </b>Interested in submitting your research, creative writing, or PhD thesis to our journal? We welcome contributions that meet our high academic and creative standards. Please send your submissions or inquiries about the submission process to: raviphd2012@gmail.com
          </p><br></br>

          <p>
          <b>Admission Counseling Services: </b>For information about our admission counseling services, including guidance on college and university applications, please reach out to our admissions team: raviphd2012@gmail.com
          </p><br></br>

          <p>
          <b>Editorial Board: </b>If you have questions or feedback for our editorial board, or if you are interested in joining our team of reviewers, please contact us at: raviphd2012@gmail.com
          </p><br></br>

          <p>
          <b>Technical Support: </b>Experiencing issues with our website or online services? Our technical support team is here to help: raviphd2012@gmail.com
          </p><br></br>

          <p>
          <b>Mailing Address: </b>For those who prefer traditional correspondence, you can reach us at our mailing address:
          Alchemist Journal of Humanities,
          Sector 3, House No. 1410
          Kurukshetra, Haryana
          India
          </p><br></br>

          <p>
          <b>Phone Number: </b>+91 7015434659
          </p><br></br>

          <p>Thank you for your interest in the Alchemist Journal of Humanities. We are dedicated to fostering a vibrant intellectual community and are here to support you every step of the way.</p>
          </div>
        </div>
      </section>

    </div>
  );
};

export default ContactPage;
